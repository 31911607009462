.NodataImage{
    text-align: center;
    img{
        width: 50%;
    }
    .noDataText{
        color: #c0c8cf;
        font-size: 19px;
        margin-top: 0;
    }
}