/* styles.scss */

// Input field styles
.searchInput {
    width: 110px;
    height: 30px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  // Button styles
  .searchButton {
    // background-color: #007bff;
    // color: #fff;
    // border: none;
    // padding: 5px 10px;
    // border-radius: 5px;
    cursor: pointer;
  
    &:hover {
      // background-color: #0056b3;
    }
  }
  .tickets{
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .searchBar{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
  }
  .title{
   text-align: left !important;
   padding-left: 20px;
  }
  .searchBarWrapper {
    display: flex;
    align-items: center;
    margin-left: 10px; /* Adjust the margin as needed for spacing between items */
  }
  .NodataImage{
    text-align: center;
    img{
        width: 50%;
    }
    .noDataText{
        color: #c0c8cf;
        font-size: 19px;
        margin-top: 0;
    }
}
